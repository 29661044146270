module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136792782-38"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1637988513176961","trackViewContent":false},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"divina-de-campo","accessToken":"MC5YYVp4ZnhJQUFDQUFxaHly.IRVoFO-_ve-_vQJp77-977-977-9EkDvv70hDe-_vUAZFe-_vVDvv71M77-9MF_vv73vv71GFwI","previews":false,"pages":[{"type":"Page","match":"/:uid","path":"/page","component":"/opt/build/repo/src/templates/page.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
