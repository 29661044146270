exports.linkResolver = doc => {
  if (doc === null) return null;
  // Pretty URLs for known types

  if (doc.__typename === "PRISMIC__ExternalLink")
    return { external: true, url: doc.url };
  if (doc.__typename === "PRISMIC_Page")
    return { external: false, url: "/" + doc._meta.uid };

  if (doc.type === "Page") return "/" + doc.uid;
  if (doc.type === "Blog") return "/blog/" + doc.uid;
  if (doc.type === "Program") return "/program/" + doc.uid;
  if (doc.type === "Story") return "/story/" + doc.uid;

  // Fallback for other types, in case new custom types get created
  return "/doc/" + doc.id;
};
