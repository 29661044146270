/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import { StoreProvider } from "@Context/store-context.js";
import { registerLinkResolver } from "gatsby-source-prismic-graphql";
import { linkResolver } from "./src/utilities/link-resolver.js";

registerLinkResolver(linkResolver);

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);
